<template>
  <SecondaryAddBtn v-if="showSecondaryBtn" class="mb-8" title="Add Accessories" @click="handleAdd" />
  <a-form-item v-else name="accessories" label="Accessories used" class="accessories-content">
    <ShareCommonSelectBtn
      v-if="chooseList.length"
      :class="showSelectInput ? 'mb-3' : ''"
      :list="chooseList"
      :add-btn="!showSelectInput"
      @add="handleShowSelect"
    >
      <template #default="{ item }">
        <img width="24" height="24" :src="getOssProcessedImage(item.icon, 'm_fill,w_50,h_50', 'webp')" class="mr-1.5" />
        <span>{{ item.value }}</span>
        <icon-font class="ml-2 cursor-pointer" type="icon-close-line" @click="handleRemove(item.value)" />
      </template>
    </ShareCommonSelectBtn>
    <div v-show="showSelectInput">
      <df-select
        v-model:value="selected"
        mode="tags"
        size="large"
        placeholder="Please select or enter the name of the accessory to confirm."
        @change="handleSelChange"
        @dropdown-visible-change="handleDropdownVisibleChange"
      >
        <a-select-option v-for="(item, index) in accessoryList" :key="index" :value="item.value">
          <img v-show="item.icon" class="mr-1" :src="getOssProcessedImage(item.icon, 'm_fill,w_50,h_50', 'webp')" width="30" height="30" />
          <span>{{ item.label }}</span>
        </a-select-option>
      </df-select>
    </div>
  </a-form-item>
</template>
<script lang="ts" setup>
import { getBaseDataApi } from '@/api/common';
import type { SelectProps } from 'ant-design-vue';
import { type PropType } from 'vue';
import { COMMUNITY_BASE_DATA } from '~/types/constant';
import SecondaryAddBtn from '../SecondaryAddBtn.vue';
const showSecondaryBtn = ref(true);
const accessories = defineModel('accessories', { type: Array as PropType<any>, default: () => [] });
const selected = ref<string[]>([]);
const accessoryList = ref<SelectProps['options']>([]);
const addSelect = ref(false);
const dropdownVisible = ref(false);
const showSelectInput = computed(() => {
  if (dropdownVisible.value) {
    return true;
  } else {
    return !chooseList.value.length || addSelect.value;
  }
});
const handleDropdownVisibleChange = (visible: boolean) => {
  nextTick(() => {
    dropdownVisible.value = visible;
    if (chooseList.value.length && !visible) {
      toggleSelectInput(false);
    }
  });
};
const toggleSelectInput = (status: boolean) => {
  addSelect.value = status;
};
const handleShowSelect = () => {
  toggleSelectInput(true);
};
watchEffect(() => {
  if (!showSecondaryBtn.value) {
    return;
  }
  if (!!accessories.value.length) {
    showSecondaryBtn.value = false;
  }
});
const handleAdd = () => {
  showSecondaryBtn.value = false;
};
const chooseList = computed(() => {
  return accessoryList.value?.filter((item: any) => accessories.value.includes(item.value)) || ([] as any[]);
});
const handleSelChange = (val: any) => {
  accessories.value = [...new Set([...accessories.value, ...val])];
  selected.value = [];
};
const handleRemove = (val: string) => {
  accessories.value = accessories.value.filter((item: string) => item !== val);
};
const getBaseAccessoryData = async () => {
  const { data: baseAccessoryData, error: baseAccessoryDataError } = await getBaseDataApi(COMMUNITY_BASE_DATA.ACCESSORY);
  if (!baseAccessoryDataError.value && baseAccessoryData.value?.data?.length) {
    accessoryList.value = baseAccessoryData.value.data.map((item: any) => {
      return {
        ...item,
        value: item.name,
        label: item.name,
        icon: item?.extra?.icon,
      };
    });
  }
};
onMounted(() => {
  nextTick(() => {
    getBaseAccessoryData();
  });
});
</script>
<style lang="less">
.accessories-content {
  .ant-btn.ant-btn-lg {
    height: 48px;
    border-radius: 8px;
  }
}
</style>
