<template>
  <share-project-layout
    type="project"
    :save-loading="contentStatus.saveLoading"
    :time="time"
    :submit-loading="contentStatus.submitLoading"
    :is-published="contentStatus.isPublished"
    :is-change-router="isChangeRouter"
    :init-loading="contentStatus.initLoading"
    :has-user-edited="contentStatus.hasUserEdited"
    :mode="mode"
    @emit-next="clickNext"
    @emit-save="save"
  >
    <share-project-basic-infomation ref="step1" />
    <share-project-production-info :id="FormIds.ProductionInfo" ref="step2" />
    <lazy-share-project-instruction :id="FormIds.Instruction" ref="step3" />
    <share-project-wrap-up :id="FormIds.WrapUp" ref="step4" class="mb-4" />
    <share-common-media-library :init-medias="initMedias" class="mobile:hidden" @file-change="handleUpdateMediaFiles" />
    <template #preview>
      <ProjectDetails is-preview :preview-detail="finalForm" />
    </template>
  </share-project-layout>
</template>
<script lang="ts" setup>
import { getBaseDataApi } from '@/api/common';
import { createProjectApiV2, editProjectApiV2, getProjectDetailApi } from '@/api/index';
import { getMakingAuditDetailApi } from '@/api/project-details';
import { useLibrary } from '@/components/share/common/media-library/useLibrary';
import { FormIds } from '@/components/share/project/type';
import { validateMediaUploaded } from '@/components/share/utils';
import { useUserInfo } from '@/stores/user';
import { storeToRefs } from 'pinia';
import { useShareProject } from '~/stores/shareProject.js';
import { RESPONSE_CODE } from '~/stores/types';
import { DETAIL_TYPE } from '~/types';
import { COMMUNITY_BASE_DATA } from '~/types/constant';
import { ModeType } from '~/types/share';
import { autoFillBaseData, filterUploadingFiles, getDefaultLincense } from '../useFormIntegration';
import { initBaseData, useAutoSave, useGuide, useScroll, useSeo } from './hook.js';

const { resetLibraryFileList } = useLibrary();
const isChangeRouter = ref(false);
useScroll();
useSeo();
useGuide();
initBaseData();

const { sensorsSaveMakingClickEvent, sensorsPublishMakingClickEvent, sensorsContentMaking, sensorsPublishFinish } = useSensors();

const useShareProjectStore = useShareProject();
const { form: storeForm, isEdit, requestForm, isAIEditor, coverList } = storeToRefs(useShareProjectStore);
const mode: ComputedRef<ModeType> = computed(() => (useShareProjectStore.form.basicForm.forSale ? ModeType.Sale : ModeType.Free));
const initMedias = computed(() => {
  return requestForm.value?.extra?.medias || [];
});
const finalForm = computed(() => {
  const { wrapUpForm, instructionForm, basicForm, productionForm, ...other } = useShareProjectStore.form;
  return {
    ...wrapUpForm,
    ...instructionForm,
    ...basicForm,
    ...productionForm,
    ...other,
  };
}) as any;

const router = useRouter();
const route = useRoute();
const step1 = ref(null) as any;
const step2 = ref(null) as any;
const step3 = ref(null) as any;
const step4 = ref(null) as any;

/** 校验*/
const clickNext = async () => {
  const pub1 = await step1.value.handleFormat();
  const pub2 = await step2.value.handleFormat();
  const pub3 = await step3.value.checkVailDate();
  const pub4 = await step4.value.checkVailDate();
  // 文件上传完毕
  const checkAttachFiles = finalForm.value.attachFiles && finalForm.value.attachFiles.every((file: any) => file.url);
  await validateMediaUrl();
  let checkFile = true;
  if (finalForm.value.hasInstruction === true && finalForm.value.instructionType === 'file') {
    checkFile = finalForm.value.tutorialFiles.length > 0 && finalForm.value.tutorialFiles.every((item: any) => item.url || item.videoUrl);
  }
  goNext(pub1 && pub2 && pub3 && pub4 && checkAttachFiles && checkFile);
};
const validateMediaUrl = async () => {
  const { projectFileList, steps } = finalForm.value;
  try {
    const stepList = steps.reduce((prev: any, current: any) => {
      const curr = [...current.imageList, ...current.videos];
      return [...prev, ...curr];
    }, []);
    return validateMediaUploaded([...coverList.value, ...projectFileList, ...stepList].map((item) => item.url || item.videoUrl));
  } catch (error) {
    return false;
  }
};
// 执行下一步
const goNext = async (isVail: boolean) => {
  if (isVail) {
    publish();
  } else {
    message.error({
      content: 'Steps can be highlighted after completing the required fields',
      key: 'next',
    });
    // 展开折叠，定位第一个错误提示
    await useShareProjectStore.initActivityKey();
    setTimeout(() => {
      var errors = document.querySelector('.ant-form-item-explain-error');
      if (errors) {
        errors.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
          inline: 'nearest',
        });
      }
    }, 300);
  }
};

const contentStatus = reactive({
  saveLoading: false,
  submitLoading: false, // 提交loading
  isPublished: true, // 是否已发布
  initLoading: false, // 请求编辑数据的loading
  isRequested: false, // 是否已经请求完初试数据
  hasUserEdited: false, // 是否手动修改过数据
});

// 初始化编辑状态
const initStatus = () => {
  const { id } = route.query;
  if (id) {
    useShareProjectStore.setIsEdit(true);
  }
};
const setDefaultLicense = async () => {
  try {
    const defaultLicenseId = await getDefaultLincense();
    !!defaultLicenseId && useShareProjectStore.setDefaultLicense(defaultLicenseId);
  } catch (error) {
    console.log('setDefaultLicense-err', error);
  }
};
const getLicenseList = async () => {
  try {
    const res = await getBaseDataApi(COMMUNITY_BASE_DATA.LICENSE);
    const list = res?.data?.value?.data ?? [];
    useShareProjectStore.setLicenseList(list);
  } catch (err) {
    console.log('getLicenseList-err', err);
  }
};
const autoFillProjectData = async () => {
  // 设置的默认license和remix自动填入
  try {
    // const defaultLicenseId = await getDefaultLincense();
    const recentForm = await autoFillBaseData();
    useShareProjectStore.setAutoFileForm(recentForm);
    // !!defaultLicenseId && useShareProjectStore.setDefaultLicense(defaultLicenseId);
  } catch (err) {
    console.log('autoFillProjectData-err', err);
  }
};
watch(() => route.query, initStatus, { deep: true });
// 请求编辑数据
const initForm = async () => {
  contentStatus.initLoading = true;
  const { infoType, id } = route.query;
  const editType: string = infoType ? 'reAudit' : 'edit'; // 要反显的内容:二次审核(reAudit)/二次编辑(edit)
  // await autoFillProjectData();
  await setDefaultLicense();
  await getLicenseList();
  // 编辑状态 请求编辑案例的详情
  if (id) {
    try {
      const map: any = {
        edit: getProjectDetailApi, // 编辑: 获取线上版本详情
        reAudit: getMakingAuditDetailApi, // 重新审核: 获取审核版本详情
      };
      const { data: getProjectDetailResp, error: getProjectDetailError } =
        editType === 'reAudit' ? await map[editType](Number(id)) : await map[editType](Number(id), 'projectEditor');
      if (!getProjectDetailError?.value && getProjectDetailResp?.value) {
        await useShareProjectStore.setRequestForm(getProjectDetailResp.value.data);
        const status = getProjectDetailResp.value?.data?.status;
        contentStatus.isPublished = status === DETAIL_TYPE.PUBLISHED;
        const aiImages = getProjectDetailResp.value?.data?.aiImages;
        isAIEditor.value = aiImages && aiImages.length > 0;
      }
    } catch (err) {
      console.log('getData-err', err);
    } finally {
    }
  } else {
    await autoFillProjectData();
    contentStatus.isPublished = false;
    isAIEditor.value = false;
  }
  contentStatus.initLoading = false;
  // 由于vue-store数据变更的异步，所以延迟200ms再修改请求状态，便于监听是否手动修改数据
  setTimeout(() => {
    contentStatus.isRequested = true;
    useShareProjectStore.updateRequestStatus(true);
  }, 200);
};
// 监听是否手动修改数据
watch(
  () => [storeForm.value.basicForm, storeForm.value.productionForm, storeForm.value.wrapUpForm, storeForm.value.instructionForm],
  () => {
    if (contentStatus.isRequested) {
      nextTick(() => {
        contentStatus.hasUserEdited = true;
      });
    }
  },
  { deep: true },
);

// 点击保存
const save = async (isAuto = false) => {
  if (contentStatus.submitLoading) {
    // 正在请求提交，不允许自动保存触发
    return;
  }
  contentStatus.saveLoading = true;
  const failCallback = async (id: any, err: any) => {
    if (err.code === 91909) {
      // 保存一个已发布的案例，跳转至成功页
      isChangeRouter.value = true;
      removeTimeout(); // 清除自动保存
      const hasSetting =
        (finalForm.value.paraSettings && finalForm.value.paraSettings.length > 0) ||
        finalForm.value.steps.some((item: any) => item.paraSettings && item.paraSettings.length > 0);
      await router.replace({
        path: `/share/success`,
        query: {
          id: id ? id : finalForm.value.id,
          mode: mode.value,
          type: 'project',
          hasInstruction: finalForm.value.hasInstruction ? 'true' : 'false',
          hasSetting,
          hasaudit: finalForm.value.isAudit ? 'true' : 'false',
        },
      });
    }
    handleSensorsSaveClick({
      is_success: false,
      fail_reason: err,
      item: {
        ...finalForm.value,
        content_id: id,
        status: 'save',
      },
    });
  };
  const successCallback = async (id: any) => {
    if (!isEdit.value) {
      // 编辑已有id的案例
      storeForm.value.id = id;
      const queryParams = {
        ...route.query,
        id: id,
      };
      isChangeRouter.value = true;
      await router.replace({
        path: `/share/project`,
        query: queryParams,
      });
      nextTick(initStatus);
    }
    if (!isAuto) {
      message.success('Saved to Draft', 1);
      handleSensorsSaveClick({
        is_success: true,
        fail_reason: [''],
        item: {
          ...finalForm.value,
          content_id: id ? id : finalForm.value.id,
          status: 'save',
        },
      });
    }
  };

  if (isEdit.value) {
    // 编辑已有id的案例
    requestEditProject('new', successCallback, failCallback);
  } else {
    // 新建案例
    requestCreateProject('new', successCallback, failCallback);
  }
};
const userInfo = useUserInfo();
const userName = computed(() => userInfo.myInfo.name);
// 点击发布
const publish = async () => {
  contentStatus.submitLoading = true;
  const success = async (id: any) => {
    isChangeRouter.value = true;
    removeTimeout(); // 清除自动保存
    await message.success('Published Successfully', 1);
    const hasSetting =
      (finalForm.value.paraSettings && finalForm.value.paraSettings.length > 0) ||
      finalForm.value.steps.some((item: any) => item.paraSettings && item.paraSettings.length > 0);
    await router.replace({
      path: `/share/success`,
      query: {
        id: id ? id : finalForm.value.id,
        mode: mode.value,
        type: 'project',
        hasInstruction: finalForm.value.hasInstruction ? 'true' : 'false',
        hasSetting,
        hasaudit: finalForm.value.isAudit ? 'true' : 'false',
      },
    });
    handleSensorsPublishClick({
      is_success: true,
      fail_reason: [''],
      item: {
        ...finalForm.value,
        content_id: id ? id : finalForm.value.id,
        status: 'publish',
      },
    });
    sensorsPublishFinish({
      content_id: id ? id : finalForm.value.id,
      is_free: mode.value === ModeType.Free,
      author_name: userName.value,
    });
    contentStatus.submitLoading = false;
  };
  const fail = (id: any, err: any) => {
    handleSensorsPublishClick({
      is_success: false,
      fail_reason: err,
      item: {
        ...finalForm.value,
        content_id: id ? id : finalForm.value.id,
        status: 'publish',
      },
    });
    contentStatus.submitLoading = false;
  };

  if (isEdit.value) {
    requestEditProject('published', success, fail);
  } else {
    requestCreateProject('published', success, fail);
  }
};
// 请求创建接口
const requestCreateProject = async (status: 'new' | 'published', success: any, fail: any) => {
  let formStateFormat = JSON.parse(JSON.stringify(finalForm.value));
  formStateFormat = filterUploadingFiles(formStateFormat);
  formStateFormat.status = status;

  try {
    const { data: createProjectResp, error: createProjectError } = await createProjectApiV2('project', {
      ...formStateFormat,
      referrer: document.referrer,
      newType: 'template',
    });
    if (createProjectError.value || createProjectResp.value?.code !== RESPONSE_CODE.SUCCESS) {
      fail('', createProjectError.value || createProjectResp.value);
      throw createProjectError.value;
    }
    const currentId = createProjectResp.value?.data.id;
    success(currentId);
  } catch (err) {
    console.log(['createProject==>', err]);
    message.error({
      content: 'Sorry, something went wrong, please try again later',
      key: 'next',
    });
    fail('', err);
  } finally {
    contentStatus.saveLoading = false;
  }
};
// 请求编辑接口
const requestEditProject = async (status: 'new' | 'published', success: any, fail: any) => {
  let formStateFormat = JSON.parse(JSON.stringify(finalForm.value));
  formStateFormat = filterUploadingFiles(formStateFormat);
  formStateFormat.status = status;
  try {
    const { data: editProjectResp, error: editProjectError } = await editProjectApiV2('project', Number(finalForm.value.id), formStateFormat);
    if (!editProjectError.value) {
      if (editProjectResp.value?.code === RESPONSE_CODE.SUCCESS) {
        success(Number(finalForm.value.id));
      } else {
        fail(Number(finalForm.value.id), editProjectResp.value);
      }
    } else {
      fail(Number(finalForm.value.id), editProjectError.value);
    }
  } catch (err) {
    console.log(['editProject==>', err]);
    message.error({
      content: 'Sorry, something went wrong, please try again later',
      key: 'next',
    });
    fail(Number(finalForm.value.id), err);
  } finally {
    contentStatus.saveLoading = false;
  }
};

// 埋点
const handleSensorsSaveClick = (...args: any[]) => {
  const { is_success, fail_reason, item } = args[0];
  sensorsSaveMakingClickEvent({
    is_success,
    fail_reason,
  });
  if (is_success) {
    handleSensorsContentMaking(item);
  }
};
const handleSensorsPublishClick = (...args: any[]) => {
  const { is_success, fail_reason, item } = args[0];

  sensorsPublishMakingClickEvent({
    is_success,
    fail_reason,
    making_type: 'project',
    content_type: mode.value,
  });
  if (is_success) {
    handleSensorsContentMaking(item);
  }
};
const userInfoStore = useUserInfo();
const handleSensorsContentMaking = (item: any) => {
  sensorsContentMaking({
    content_id: item.content_id,
    xtool_community_id: userInfoStore.myInfo.id,
    content_name: item.title,
    content_tag: item?.contentTags || [],
    author_name: userInfoStore.myInfo.name,
    machine: item.deviceName,
    material: item?.materials || [],
    service_type: item.type,
    status: item.status,
    content_type: mode.value,
  });
};
const handleUpdateMediaFiles = (files: any) => {
  useShareProjectStore.form.extra.medias = [...(files || [])] as any;
};

watch(
  () => route.query,
  () => {
    // 解决 share/xx?id 跳转到 新建share/xx  不清空数据的情况
    if (route.path.includes('/share/project') && (!route.query.id || route.query.id !== storeForm.value.id) && !isChangeRouter.value) {
      location.reload();
    }
    isChangeRouter.value = false;
  },
  { deep: true },
);

// 自动保存
const { removeTimeout, time } = useAutoSave(contentStatus, save);

onMounted(() => {
  nextTick(() => {
    initStatus();
    initForm();
  });
});
onUnmounted(() => {
  useShareProjectStore.$reset();
  resetLibraryFileList();
});
</script>
<style lang="less">
.layout-driver-custom {
  max-width: 280px;
  border-radius: 10px;
  background: #5f65e7;
  color: #fff;
  z-index: 99;
  top: auto !important;
}
.driver-popover * {
  color: #fff;
}
.layout-driver-custom .driver-popover-arrow {
  border: 5px solid #5f65e7;
  border-right-color: transparent;
  border-top-color: transparent;
  border-left-color: transparent;
}
.layout-driver-custom {
  .driver-popover-next-btn {
    background-color: #fff !important;
    color: #5f65e7;
    border: none;
    font-size: 14px;
    border-radius: 40px;
    width: 80px;
    text-align: center;
    height: 36px;
    margin-top: 20px;
    text-shadow: none;
    font-weight: theme('fontWeight.bold');
  }
}
.driver-overlay {
  display: none !important;
}
.driver-active .driver-overlay,
.driver-active * {
  pointer-events: auto !important;
}

.driver-popover-arrow-side-bottom.driver-popover-arrow-align-end {
  right: 50%;
}
.driver-popover-close-btn,
.driver-popover-close-btn:hover,
.driver-popover-close-btn:focus {
  color: #fff;
}
</style>
<style lang="less" scoped>
:deep(.icon-delete) {
  &:hover {
    color: theme('colors.status.warning');
  }
}
</style>
