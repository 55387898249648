<template>
  <a-form-item name="attachFiles">
    <div v-if="tipsShow" class="mb-3 flex items-center justify-between rounded-lg bg-[#e9f2fe] px-4 py-3">
      <div class="flex items-center gap-2">
        <icon-font class="text-2xl text-status-link" type="icon-under-review" />
        <span class="text-level-4 text-uni-13">
          Tips：Template files are uploaded here, tutorial files can be uploaded in the
          <span class="cursor-pointer text-status-link" @click="goInstruction">Instruction</span>
          <span>.</span>
        </span>
      </div>
      <icon-font class="cursor-pointer text-2xl text-uni-7" type="icon-close-line" @click="tipsShow = false" />
    </div>
    <common-file-list ref="fileListRef" v-model:list="attachFiles" :file-limit="FILE_LIMIT">
      <div
        :id="GuideIds.projectFiles"
        :class="[
          'w-full cursor-pointer rounded-lg border-[1px] border-dashed border-[#1a1a1a]/[0.18] bg-background-2 px-10 py-9 text-center hover:border-[#1a1a1a]/[0.4]',
        ]"
      >
        <icon-font class="py-6 text-[54px]" type="icon-Upload" />
        <div class="pb-2 text-level-2 font-semibold text-[#121212]">
          <span class="text-error">*</span>
          {{ ShowText.title }}
        </div>
        <div class="mx-auto text-level-4 text-[#999999]" v-html="ShowText.hint"></div>
      </div>
    </common-file-list>
    <div class="choose-btn mt-3 flex items-center gap-2">
      <df-btn type="text" @click="handleInputUpload">
        <icon-font class="!text-[20px] text-[#565656]" type="icon-zip1" />
        <span class="text-level-4 font-semibold text-[#565656]">Add Extra Files</span>
      </df-btn>
      <input ref="fileInput" type="file" :accept="info.zipFormat" class="upload-input" @change="handleUploadZipFile" />
    </div>
  </a-form-item>
</template>
<script setup lang="ts">
import { getDesignFileDetail } from '@/api/user';
import { handleSensorEditClick } from '@/components/share/utils';
import { useShareProject } from '@/stores/shareProject';
// import { v4 } from 'uuid';
import { CloudFileParser } from '@/utils/canvas/cloudFileParser';
import { GuideIds } from '~/components/share/project/type';

const useShareProjectStore = useShareProject();
type PropType = {
  info: {
    format: string;
    zipFormat: string;
    hint: string;
    maxFileSizeText: string;
    maxFileSize: number;
    maxCount: number;
    errSizeTips: string;
    errTypeTips: string;
    zipMaxCount: number;
  };
  mode: string;
};
const fileListRef = ref();
const fileInput = ref();
const tipsShow = ref(true);

const goInstruction = () => {
  useShareProjectStore.callComponentMethod('file');
  handleSensorEditClick({ name: 'uploadfile_Instruction' });
};
const props = defineProps<PropType>();
const addXcsList: any = ref([]);
// const handleAddXcs = (list: any) => {
//   const xcsList = list.map((item: any) => {
//     return {
//       uid: v4(),
//       ...item,
//     };
//   });
//   addXcsList.value = [...xcsList];
// };

const { info, mode } = toRefs(props);
const FILE_LIMIT = computed(() => ({ ...info.value }));
const attachFiles = defineModel('attachFiles', { type: Array, default: () => [] });
const ShowText = computed(() => {
  return {
    title: 'Select or Upload Files',
    hint: info.value.hint,
  };
});
const { sensorEditorDocUpload } = useSensors();
const route = useRoute();

const { id, designId, designType } = route.query;
const type = ref('') as any;
watch(addXcsList, () => {
  sensorEditorDocUpload({
    element_name: 'Select from XCS',
    sevice_type: type.value,
    content_type: String(mode),
    content_id: Number(id),
  });
  attachFiles.value = [...attachFiles.value, ...addXcsList.value];
});
watch(
  () => attachFiles.value.length,
  () => {
    sensorEditorDocUpload({
      element_name: 'Select or Upload Files',
      sevice_type: type.value,
      content_type: String(mode),
      content_id: Number(id),
    });
  },
);
const setUserCenterFile = async () => {
  if (id) {
    return;
  }
  if (designId) {
    try {
      const res = await getDesignFileDetail({
        type: designType as string,
        id: designId as string,
      });
      const { data } = res.data.value;
      switch (designType) {
        case 'new_cloud':
          handleNewCloudData(data.newCloudProject);
          break;
        case 'old_cloud':
          handleOldCloudData(data.oldCloudProject);
          break;
        case 'df':
          handleDfData(data.dfProject);
          break;
        default:
          break;
      }
    } catch (error) {
      console.log(error);
    }
  }
};
// 处理新版云端项目数据
const handleNewCloudData = async (newCloudProject: any) => {
  try {
    const file = await CloudFileParser.assembleToXCSFile(newCloudProject);
    file && handleUploadFile(file);
  } catch (error) {
    console.error('处理新版云端项目数据时出错:', error);
  }
};

// 处理旧版云端项目数据
const handleOldCloudData = async (oldCloudProject: any) => {
  const { url } = oldCloudProject;
  if (!url) {
    return;
  }
  try {
    const file = await downloadFile(url);
    file && handleUploadFile(file);
  } catch (error) {
    console.error('下载或上传文件时出错:', error);
    message.error('文件处理失败，请重试');
  }
};
const handleUploadFile = async (file: File) => {
  try {
    // 使用fileListRef处理文件上传
    fileListRef.value.addCurrentFileList([file]);
    await fileListRef.value.onFileChange({
      file,
    });
  } catch (error) {
    console.error('上传文件时出错:', error);
  }
};
const downloadFile = async (url: string) => {
  try {
    // 下载文件
    const response = await fetch(url);
    const blob = await response.blob();
    // 从URL中获取文件名
    const fileName = url.split('/').pop() || 'downloaded_file';
    // 创建File对象
    const file = new File([blob], fileName, { type: blob.type });
    return file;
  } catch (error) {
    console.error('下载文件时出错:', error);
  }
};

// 处理设计文件数据
const handleDfData = (dfProject: any) => {
  if (dfProject.id) {
    delete dfProject.id; // 按新增的逻辑走，不要id，不然没法区分是已有的，还是新增的，新增的走自动填充逻辑
    attachFiles.value.push(dfProject);
  }
};
const handleInputUpload = () => {
  fileInput.value?.click();
};
const isZipOrRarFile = (fileName: string): boolean => {
  const format = info.value?.zipFormat.split(',');
  const extension = fileName.toLowerCase().split('.').pop();
  return format.includes('.' + extension || '');
};
const handleBeforeUpload = (files: any) => {
  const maxCount = info.value.zipMaxCount;
  const curFileZipList = attachFiles.value.filter((item: any) => isZipOrRarFile(item.name));

  if (curFileZipList.length >= maxCount) {
    const tips = 'you can only add to ' + info.value.zipMaxCount + ' files .';
    message.info({
      content: tips,
      key: 'upload-file',
    });
    return;
  } else {
    fileListRef.value.addCurrentFileList(files);
  }
  return false;
};

const handleUploadZipFile = async (event: Event) => {
  const target = event.target as HTMLInputElement;
  if (!target.files) {
    return;
  }
  const files = [...target.files];

  if (!fileListRef.value || !files?.length) {
    return;
  }
  try {
    for (let i = 0; i < files.length; i++) {
      const result = await handleBeforeUpload(files);
      if (result === false) {
        await fileListRef.value.onFileChange(
          {
            file: files[i],
            fileList: files,
          },
          info.value.zipFormat,
        );
      }
    }
    target.value = '';
  } catch (error) {
    target.value = '';
    console.warn(error);
  }
};
onMounted(() => {
  type.value = route.path.split('/')[2];
  nextTick(() => {
    setUserCenterFile();
  });
});
</script>

<style lang="less" scoped>
.choose-btn {
  .upload-input {
    display: none;
  }
}
</style>
