<template>
  <a-card class="card-style mt-5 px-5 py-3 mobile:rounded-none mobile:px-1">
    <a-collapse v-model:activeKey="activeKey" ghost>
      <template #expandIcon="item">
        <icon-font type="icon-arrow-right-m" :class="{ 'rotate-90': item?.isActive }" />
      </template>
      <a-collapse-panel :key="CollapseKey.WrapUp" class="panel-box" :force-render="true">
        <template #header>
          <div class="header-box cursor-default">
            <span class="text-[20px] font-semibold">Wrap up</span>
          </div>
        </template>
        <a-form ref="wrapUpRef" :model="wrapUpState" layout="vertical">
          <Tags v-model:contentTags="wrapUpState.contentTags" :rules="rules" :cover-auto-tag="coverAutoTag" />
          <div class="mt-8">
            <CustomizationTags v-model:printTags="wrapUpState.printTags" :cover-auto-tag="coverAutoTag" />
          </div>
          <a-form-item class="mb-0 mt-8">
            <a-checkbox v-model:checked="wrapUpState.aiAssistance">
              <span class="text-level-4 font-semibold mobile:text-level-4">This drawing is designed with the help of AI</span>
            </a-checkbox>
            <p class="mb-0 mt-2 text-level-4 text-text-tertiary mobile:text-level-7">
              Select this option if your work contains AI-generated elements. Other users will be informed. AI-generated content must adhere strictly
              to our
              <a href="https://www.atomm.com/discussion/1102" target="_blank">AI Content Policy</a>
              .
            </p>
          </a-form-item>
        </a-form>
      </a-collapse-panel>
    </a-collapse>
  </a-card>
</template>
<script setup lang="ts">
import CustomizationTags from '@/components/share/common/basic-info/CustomizationTags.vue';
import Tags from '@/components/share/common/basic-info/Tags.vue';
import { CollapseKey } from '@/components/share/project/type';
import { useShareProject } from '@/stores/shareProject';
import { storeToRefs } from 'pinia';
const useShareProjectStore = useShareProject();
const { activeKey, coverAutoTag } = storeToRefs(useShareProjectStore);
const wrapUpState = ref({
  contentTags: [],
  aiAssistance: false,
  printTags: [], // POD-标签
} as any);
const rules = computed(() => {
  return {
    contentTags: [
      {
        required: true,
        message: 'Tags is required',
        trigger: 'change',
      },
    ],
  };
}) as any;

watch(
  () => useShareProjectStore.requestForm,
  (val) => {
    const { contentTags, aiAssistance, printTags } = val;
    wrapUpState.value.contentTags = contentTags ? contentTags : [];
    wrapUpState.value.aiAssistance = aiAssistance;
    wrapUpState.value.printTags = printTags ? printTags : [];
  },
  { deep: true },
);
watch(
  () => wrapUpState,
  () => {
    useShareProjectStore.setForm('wrapUpForm', wrapUpState.value);
  },
  { deep: true },
);
watch(
  () => wrapUpState.value.contentTags,
  // 为了解决【点击aiTag时，不会消掉校验提示 】的bug
  (newVal, oldVal) => {
    if (newVal.length !== oldVal.length) {
      wrapUpRef.value?.validateFields(['contentTags']);
    }
  },
);

const wrapUpRef = ref(null) as any;
const checkVailDate = async () => {
  try {
    await wrapUpRef.value.validate();
    return true;
  } catch (e) {
    console.error(e);
    return false;
  }
};
defineExpose({
  checkVailDate,
});
</script>
<style lang="less" scoped>
.card-style {
  :deep(.ant-card-body) {
    padding: 0;
    .ant-form-item .ant-form-item-label > label {
      font-size: 14px;
      font-weight: theme('fontWeight.semibold');
    }
  }
  :deep(.ant-select-focused .ant-select-selector) {
    background-color: #ffffff;
  }
}
.panel-box {
  border-radius: 0 !important;
  :deep(.ck.ck-toolbar > .ck-toolbar__items > *:not(.ck-toolbar__line-break)) {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  :deep(.ck.ck-toolbar__separator) {
    display: none;
  }
}
:deep(.ant-collapse) > .ant-collapse-item > .ant-collapse-header {
  align-items: center;
  .ant-collapse-expand-icon {
    .anticon {
      color: #1a1a1a;
      font-size: 24px;
    }
  }
}
.level-btn {
  :deep(.ant-btn) {
    border-radius: 8px;
    font-size: 16px;

    &.ant-btn-text {
      background: theme('colors.background.2');
    }
  }
  :deep(.df-btn.ant-btn-default:not(:disabled):hover) {
    background-color: transparent;
  }
}
@media screen and (max-width: 768px) {
  .level-btn {
    :deep(.ant-btn) {
      font-size: 14px;
    }
  }
}
</style>
