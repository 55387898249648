<template>
  <a-form-item label="Difficulty" name="difficultyLevelId">
    <p class="m-0 mt-[-6px] text-text-tertiary">Select the difficulty level for your creative work</p>
    <div class="level-btn mt-4 flex gap-2 mobile:mt-3">
      <a-popover v-for="item in baseDiffucultyLevel" :key="item.id" :placement="isPc ? 'top' : 'topRight'">
        <template #content>
          <div class="relative h-[320px] w-[312px] mobile:h-[280px] mobile:w-[200px]">
            <img
              :src="getOssProcessedImage(item.extra.exampleImage, 'm_fill,w_100,h_100', 'webp')"
              alt="level"
              class="mb-2 h-[234px] w-full rounded-lg mobile:h-[150px]"
            />
            <p class="mb-1 text-level-2 font-semibold">
              {{ item.name }}
            </p>
            <p class="m-0 mt-2 text-level-7 text-text-tertiary">
              {{ item.extra.desc }}
            </p>
            <span class="absolute right-2 top-2 rounded-md bg-[#212121]/[80%] px-2 py-1 text-level-7 text-[#fff] mobile:right-1 mobile:top-1">
              Sample
            </span>
          </div>
        </template>
        <df-btn
          class="difficulty-btn"
          :type="difficultyLevelId == item.id ? 'dark' : 'filled'"
          shape="default"
          size="large"
          @click="changeLevel(item.id)"
        >
          {{ item.name }}
        </df-btn>
      </a-popover>
    </div>
  </a-form-item>
</template>
<script setup lang="ts">
import { getDiffucultyLevelData } from '@/api/common';
const difficultyLevelId = defineModel('difficultyLevelId');
const baseDiffucultyLevel = ref([] as any[]);
const isPc = ref(window.innerWidth > 768);
const getBaseDiffucultyLevelData = async () => {
  const res = await getDiffucultyLevelData();
  baseDiffucultyLevel.value = res?.data?.value?.data ?? [];
};
const changeLevel = (id: string) => {
  if (difficultyLevelId.value === id) {
    difficultyLevelId.value = null;
  } else {
    difficultyLevelId.value = id;
  }
};
onMounted(() => {
  nextTick().then(() => {
    getBaseDiffucultyLevelData();
  });
});
</script>

<style lang="less" scoped>
.difficulty-btn {
  &.df-btn.df-btn--filled.df-btn--large,
  &.df-btn.df-btn--default.df-btn--large {
    font-weight: 400;
  }
}
</style>
