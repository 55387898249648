<template>
  <a-modal :open="visible" width="810px" :footer="false" :mask-closable="false" wrap-class-name="select-display-modal" @cancel="close">
    <div class="select-canvas-modal pb-5 pt-6">
      <div class="title select-none px-8 pb-1">Template File</div>
      <div class="select-none px-8 text-text-tertiary">Please select the object for which you'd like to auto-fill settings.</div>
      <div class="scroll-style max-h-[calc(100vh-200px)] overflow-y-auto">
        <div class="my-4 select-none px-8">
          <a-checkbox
            v-for="item in allSetting"
            :key="item.value"
            v-model:checked="item.check"
            :value="item.value"
            class="mb-1"
            @click="clickCheckbox(item)"
          >
            <span class="inline-block w-[70px]">{{ item.label }}</span>
          </a-checkbox>
        </div>
        <client-only v-if="visible">
          <div class="select-none px-8">
            <div class="tips">
              <span>Select multiple objects using</span>
              <span class="highlight">&nbsp;Shift&nbsp;</span>
              <span>while clicking each.</span>
            </div>
          </div>
          <canvas-m-canvas-list
            class="px-8"
            :editor-store="editorStore"
            :project-store="projectStore"
            :loading="loading"
            :canvas-list="canvasList"
            :current-id="currentId"
            :current-select-display="currentSelectDisplay"
            @select-display="selectDisplay"
            @close="close"
          />
        </client-only>
        <div
          v-if="selectList.length == 0"
          class="mx-8 mb-3 mt-7 flex h-[168px] w-[calc(100%-64px)] items-center justify-center rounded-lg bg-background-2"
        >
          <p v-if="allSetting.length > 0 || loading" class="text-text-tertiary">Please select layer parameters</p>
          <p v-else class="text-text-tertiary">XCS file does not contain settings. Please add settings then parse.</p>
        </div>
        <div v-else class="mb-3 ml-8 mt-7 pr-8">
          <div v-for="item in selectList" :key="item" class="mb-2 rounded-lg bg-background-2 p-4 text-text-primary">
            <p class="text-level-2">{{ item.label }}</p>
            <div class="flex items-center">
              <div class="min-w-[50%]">
                <icon-font type="icon-Laser" class="relative top-[1px] mr-2 text-[20px]" />

                <!-- 光线类型- 加工模式- 加工类型 -->
                <span v-if="item.laserName" class="line-after">{{ item.laserName }}</span>
                <span :class="[item.processingTypeName ? 'line-after' : '']">{{ item.modeName }}</span>
                <span>{{ item.processingTypeName }}</span>
              </div>
              <div v-if="item.materialOfficial" class="line-before">
                <img
                  v-if="item.materialOfficial?.imgUrl"
                  :src="getOssProcessedImage(item.materialOfficial?.imgUrl, 'm_fill,w_24,h_24', 'webp')"
                  class="mr-2 h-[24px] w-[24px]"
                />
                <icon-font v-else type="icon-Consumables" class="relative top-[2px] mr-2 text-[20px]" />

                <span>{{ item.materialOfficial?.name?.en }}</span>
              </div>
            </div>
            <div class="mt-4 flex flex-wrap gap-1">
              <span v-for="(val, key, i) of item.parameter" :key="key" :class="[i < Object.keys(item.parameter).length - 1 ? 'line-after' : '']">
                {{ capFirst(String(key)) }}: {{ val }}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="flex items-center justify-center gap-2 border-0 border-t border-solid border-[rgba(0,0,0,0.1)] pt-5">
        <df-btn @click="cancel">Cancel</df-btn>
        <df-btn type="primary" :disabled="selectList.length == 0" @click="emitSelect">Select ({{ selectList.length }})</df-btn>
      </div>
    </div>
  </a-modal>
</template>

<script setup lang="ts">
import { useShareProject } from '@/stores/shareProject';
import { capFirst } from '@/utils/fns/convert.js';
import { isEqual, sortBy } from 'lodash-es';
type Props = {
  visible: boolean;
  file: any;
};
const props = defineProps<Props>();
type emitsType = {
  (event: 'update:visible', value: boolean): void;
  (event: 'handleSelectDisplay', value: any): void;
};
const emit = defineEmits<emitsType>();
const close = () => {
  canvasList.value = [];
  currentId.value = '';
  allSetting.value = [];
  emit('update:visible', false);
};

const editorStore = ref(null) as any;
const canvasList = ref([] as any);
const currentId = ref('');

const initEditor = async () => {
  const { useEditorStore } = await import('@/stores/editor');
  editorStore.value = useEditorStore();
  editorStore.value.initEditor();
};
watch(
  () => editorStore.value?.currentId,
  () => {
    canvasList.value = editorStore.value?.canvasList;
    currentId.value = editorStore.value?.currentId;
  },
  { deep: true },
);
const projectStore = ref(null) as any;
const loading = computed(() => projectStore.value?.projectLoading);

const initFile = async () => {
  const { useProjectStore } = await import('@/stores/project');
  projectStore.value = useProjectStore();
  projectStore.value.projectOpenByUrl({
    url: props.file.url,
    name: props.file.name,
  });
  initEditor();
};
const allSetting = ref([] as any[]); // setting 列表
const saveIds = ref([] as any[]); // 记录选中的 画布id+元素id
// 选中的setting 列表
const selectList = computed(() => {
  return allSetting.value.filter((item: any) => item.check);
});
// 所有选择元素id，调用xcs选择ids
const currentSelectDisplay = ref([] as any[]);

const useShareProjectInfo = useShareProject();
const baseProcessTypes = computed(() => useShareProjectInfo.baseData.baseProcessTypes) as any;
const baseModes = computed(() => useShareProjectInfo.baseData.baseModes) as any;
const basePower = computed(() => useShareProjectInfo.baseData.basePower) as any;
const baseLaser = computed(() => useShareProjectInfo.baseData.baseLaser) as any;
const baseMachinesList = computed(() => useShareProjectInfo.baseData.baseMachinesList) as any;

watch(
  () => projectStore.value?.fileAllSetting,
  () => {
    allSetting.value = projectStore.value?.fileAllSetting.map((item: any, index: number) => {
      const li = index + 1;
      const { power, mode, processingType, laserType, deviceId } = item;
      const processingTypeName = baseProcessTypes.value.find((item: any) => item.code === processingType)?.name; // 加工方式
      const modeName = baseModes.value.find((item: any) => item.code === mode)?.name; // 模式
      const powerName = basePower.value.find((item: any) => item.code === String(power))?.name; // 激光头
      const laserName = baseLaser.value.find((item: any) => item.code === laserType)?.name; // 红蓝光
      const deviceName = baseMachinesList.value.find((item: any) => item.extId === deviceId)?.name; // 机型
      return {
        processingTypeName,
        modeName,
        powerName,
        laserName,
        deviceName,
        label: 'Setting' + li,
        value: index,
        check: false,
        ...item,
      };
    });
    // 默认选中第一个setting
    if (allSetting.value.length > 0) {
      setTimeout(() => {
        clickCheckbox(allSetting.value[0]);
      }, 0);
    }
  },
  { deep: true },
);

// 点击checkbox
const isCheckbox = ref(false);
const clickCheckbox = (item: any) => {
  isCheckbox.value = true;
  item.check = !item.check;
  if (item.check) {
    // 选择checkbox时，需切换至当前setting下的第一个画布
    const target = item.existCanvas[0].canvasId;
    if (target !== currentId.value) {
      currentId.value = target;
      editorStore.value.editorChangeCanvas(target);
    }
  }

  changeSelect();
};
// checkbox -->画布
// 根据checkbox选择情况，更新画布选择情况
const changeSelect = () => {
  saveAllCanvasId();
  const ids = [] as any[];
  selectList.value.forEach((item: any) => {
    const existCanvas = item.existCanvas;
    existCanvas.forEach((canvas: any) => {
      const arr = JSON.parse(JSON.stringify(canvas.displayIds));
      ids.push(...arr);
    });
  });
  currentSelectDisplay.value = ids;
  editorStore.value.canvas?.selectSpecDisplays(ids);
};
// 每次选择setting时，整理所有画布选择情况。记录选中的画布id+元素id
const saveAllCanvasId = () => {
  saveIds.value = [];
  if (selectList.value.length === 0) {
    return;
  }
  const allCanvas = [] as any[];
  selectList.value.forEach((item: any) => {
    const arr = JSON.parse(JSON.stringify(item.existCanvas));
    allCanvas.push(...arr);
  });
  //  合并具有相同canvasId的数据
  var mergedArray = allCanvas.reduce(function (result: any, obj: any) {
    var idx = result.findIndex(function (item: any) {
      return item.canvasId === obj.canvasId;
    });
    if (idx > -1) {
      result[idx].displayIds = result[idx].displayIds.concat(obj.displayIds);
    } else {
      result.push(obj);
    }
    return result;
  }, []);
  saveIds.value = mergedArray;
};

// 选择画布  联动-->checkbox
/*
  需求：点击画布取消or选中某一元素，需取消or选中该元素对应的setting下的所有元素，该setting的checkbox选中态也需同步更新
  1.当前画布对选择前后两个数组比较 ，找到被删除or新增的元素
  2.根据元素id找到对应的setting索引
  3.删除or新增setting索引，更新allSetting
  4.调用changeSelect，更新画布选择情况
  */
const selectDisplay = (selected: any) => {
  if (isCheckbox.value) {
    // checkbox代码控制选择画布的时候会触发这种情况
    isCheckbox.value = false;
    return;
  }
  /** 点击画布 */
  const selectedId = selected.map((item: any) => item.id);
  const currentCanvas = saveIds.value.find((item: any) => item.canvasId === currentId.value);

  if (currentCanvas) {
    // 该画布已有选择元素
    const currentDisplayIds = currentCanvas.displayIds;
    if (isEqual(sortBy(selectedId), sortBy(currentDisplayIds))) {
      return;
    }
    const compare = compareDisplay(selectedId, currentDisplayIds);
    compare.addArr.forEach((item: any) => {
      allSetting.value.forEach((setting: any) => {
        if (setting.existCanvas.some((canvas: any) => canvas.displayIds.includes(item))) {
          setting.check = true;
        }
      });
    });
    compare.deleteArr.forEach((item: any) => {
      const index = allSetting.value.findIndex((setting: any) => setting.existCanvas.some((canvas: any) => canvas.displayIds.includes(item)));
      allSetting.value[index].check = false;
    });
    changeSelect();
  } else {
    // 该画布本来🈚️选择元素
    if (selectedId.length === 0) {
      return;
    }
    selectedId.forEach((item: any) => {
      allSetting.value.forEach((setting: any) => {
        if (setting.existCanvas.some((canvas: any) => canvas.displayIds.includes(item))) {
          setting.check = true;
        }
      });
    });
    changeSelect();
  }
};
const compareDisplay = (_newVal: any, _oldVal: any) => {
  const newVal = new Set(_newVal) as any;
  const oldVal = new Set(_oldVal) as any;
  const sameArr = [...new Set([...newVal].filter((x) => oldVal.has(x)))];
  const deleteArr = [] as any[];
  const addArr = [] as any[];
  _newVal.map((item: any) => {
    if (sameArr.indexOf(item) === -1) {
      addArr.push(item);
    }
  });
  _oldVal.map((item: any) => {
    if (sameArr.indexOf(item) === -1) {
      deleteArr.push(item);
    }
  });
  return {
    deleteArr,
    addArr,
  };
};
const cancel = () => {
  close();
};

onMounted(() => {
  if (props.visible === true) {
    initFile();
  }
});
watch(
  () => props.visible,
  async () => {
    if (props.visible === true) {
      initFile();
    } else {
      editorStore.value.destroyEditor();
    }
  },
);
const emitSelect = () => {
  const arr = selectList.value.map((item: any) => {
    return {
      ...item,
      file: {
        name: props.file.name,
        url: props.file.url,
      },
      type: 'xcs',
    };
  });
  emit('handleSelectDisplay', arr);
  close();
};
</script>
<style lang="less" scoped>
.select-canvas-modal {
  .title {
    color: #212121;
    font-size: 30px;
    font-weight: theme('fontWeight.semibold');
    line-height: 38px; /* 126.667% */
  }
  .file-name {
    color: #555;

    font-size: 18px;
    font-weight: theme('fontWeight.semibold');
    line-height: 26px;
    margin: 8px 0 16px;
  }
  .tips {
    text-align: center;
    gap: 8px;
    background: theme('colors.background.4');
    padding: 2px 20px;
    .highlight {
      color: theme('colors.status.alert');
      font-weight: theme('fontWeight.bold');
    }
  }
}
.line-after {
  position: relative;
  margin-right: 8px;
  padding-right: 8px;
  &::after {
    content: '';
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 1px;
    height: 16px;
    background: rgba(0, 0, 0, 0.1);
  }
}
.line-before {
  position: relative;
  margin-left: 8px;
  padding-left: 24px;
  &::after {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 1px;
    height: 16px;
    background: rgba(0, 0, 0, 0.1);
  }
}
@media screen and (max-width: 767px) {
  .select-canvas-modal {
    .title {
      font-size: 18px;
      line-height: 18px;
    }
    .file-name {
      font-size: 14px;
      line-height: 14px;
      margin: 8px 0;
    }
  }
}
.scroll-style {
  // 滚动条样式
  &::-webkit-scrollbar {
    width: 8px;
    background-color: #fff;
  }

  // 滚动条滑块样式
  &::-webkit-scrollbar-thumb {
    width: 8px;
    background-color: #d9d9de;
    border-radius: 22px;
  }

  // 滚动条轨道样式
  &::-webkit-scrollbar-track {
    width: 8px;
    background-color: #fff;
  }
}
</style>
<style lang="less">
.select-display-modal {
  .ant-modal {
    top: 30px;
    padding-bottom: 0;
  }
}
@media screen and (max-height: 710px) {
  .select-display-modal {
    .ant-modal {
      top: 10px;
    }
  }
}
.select-display-modal {
  .ant-modal-body {
    padding: 0;
  }
}
</style>
