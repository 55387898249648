<template>
  <a-form-item name="software" label="Software">
    <ShareCommonSelectBtn
      v-if="software.length"
      :class="showSelectInput ? 'mb-3' : ''"
      :list="software"
      :add-btn="!showSelectInput"
      @remove="handleRemove"
      @add="handleShowSelect"
    />
    <div v-show="showSelectInput">
      <df-select
        v-model:value="selected"
        mode="tags"
        size="large"
        :options="softwareList"
        placeholder="Please enter the software you use"
        @change="handleSelChange"
        @dropdown-visible-change="handleDropdownVisibleChange"
      />
    </div>
  </a-form-item>
</template>
<script lang="ts" setup>
import { getBaseDataApi } from '@/api/common';
import { XCS_CODE } from '@/components/share/const';
import type { SelectProps } from 'ant-design-vue';
import { type PropType } from 'vue';
import { COMMUNITY_BASE_DATA } from '~/types/constant';
const software = defineModel('software', { type: Array as PropType<any>, default: () => [] });
const addSelect = ref(false);
const dropdownVisible = ref(false);
const showSelectInput = computed(() => {
  if (dropdownVisible.value) {
    return true;
  } else {
    return !software.value.length || addSelect.value;
  }
});
const toggleSelectInput = (status: boolean) => {
  addSelect.value = status;
};
const handleDropdownVisibleChange = (visible: boolean) => {
  nextTick(() => {
    dropdownVisible.value = visible;
    if (software.value.length && !visible) {
      toggleSelectInput(false);
    }
  });
};
const handleShowSelect = () => {
  toggleSelectInput(true);
};
const selected = ref<string[]>([]);
const softwareList = ref<SelectProps['options']>([]);

const triggerFileChange = (attachFiles: any) => {
  if (attachFiles?.length && attachFiles.some((item: any) => item?.name?.indexOf('xcs') !== -1)) {
    handleSelChange([XCS_CODE]);
  } else {
    handleRemove(XCS_CODE);
  }
};
const handleSelChange = (val: any) => {
  software.value = [...new Set([...software.value, ...val])];
  selected.value = [];
};
defineExpose({
  handleSelChange,
  triggerFileChange,
});
const handleRemove = (val: string) => {
  software.value = software.value.filter((item: string) => item !== val);
};
const getBaseSoftwaresData = async () => {
  const { data: baseSoftwaresData, error: baseSoftWaresDataError } = await getBaseDataApi(COMMUNITY_BASE_DATA.SOFTWARE);
  if (!baseSoftWaresDataError.value && baseSoftwaresData.value?.data?.length) {
    softwareList.value = baseSoftwaresData.value.data.map((item: any) => {
      return {
        ...item,
        value: item.name,
        label: item.name,
      };
    });
  }
};
onMounted(() => {
  nextTick(() => {
    getBaseSoftwaresData();
  });
});
</script>
