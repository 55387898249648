<template>
  <a-form-item name="source" :label="label" class="project-origin mb-4">
    <a-radio-group v-model:value="source">
      <a-radio :value="SourceType.Original">
        <span class="text-level-2">Original</span>
        <a-tooltip title="This template is my original creation." class="text-level-2">
          <icon-font class="ml-1 text-text-tertiary hover:text-black-hover" type="icon-question-line" />
        </a-tooltip>
      </a-radio>
      <a-radio :value="SourceType.Remix" :disabled="forSale">
        <span class="text-level-2">Remix</span>
        <a-tooltip title="This template is a significant modification of an existing template." class="text-level-2">
          <icon-font class="ml-1 text-text-tertiary hover:text-black-hover" type="icon-question-line" />
        </a-tooltip>
      </a-radio>
    </a-radio-group>
  </a-form-item>
  <a-form-item v-if="isRemix" class="remix-wrap" name="originals">
    <template #label>
      <div class="flex items-center gap-2">
        Original template used in this remix
        <a-tooltip>
          <template #title>
            Please make sure the source template's license does NOT contain "NoDerivatives" attribute. For example, CC-BY-ND, CC-BY-NC-ND, Standard
            Digital File License are not allowed.
          </template>
          <icon-font type="icon-question-line" class="text-text-tertiary hover:text-black-hover" />
        </a-tooltip>
      </div>
    </template>
    <RemixLinkItem
      v-for="(item, index) in originals"
      :key="index"
      class="mb-2"
      :has-delete="true"
      :item="item"
      @handle-delete="deleteItem(item.link)"
      @click="toLink(item.link)"
    />
    <a-dropdown v-model:open="isRemix" :get-popup-container="(triggerNode: any) => triggerNode?.parentNode" :trigger="[]" class="search-dropdown">
      <df-input v-model:value="searchVal" placeholder="Please paste the URL or use autosuggest to search on Atomm" @click.capture="clickDropdown" />
      <template #overlay>
        <div v-if="searchVal" ref="dropMenu" class="drop-menu">
          <a-config-provider>
            <template #renderEmpty>
              <a-empty :image="simpleImage">
                <template #description>
                  <span>No data</span>
                </template>
              </a-empty>
            </template>
            <a-list :data-source="list" class="list" :loading="firstLoading">
              <template #renderItem="{ item }">
                <a-list-item @click="chooseItem(item)">
                  <RemixLinkItem :has-delete="false" :item="item" />
                </a-list-item>
              </template>
            </a-list>
          </a-config-provider>
          <bottom-loading
            v-if="list && list.length > 0 && !isSearchUrl && pagination.total > pagination.pageSize"
            :loading="isLoading"
            :finished="isFinished"
            @infinite="handleInfiniteLoading"
          />
        </div>
      </template>
    </a-dropdown>
  </a-form-item>
</template>
<script setup lang="ts">
import { getLicenseList, parseUrl } from '@/api/project';
import simpleImage from '@/assets/img/no-data.png';
import { type PropType } from 'vue';
import { ModeType } from '~/types/share';
import { SourceType } from '../../interface';
import RemixLinkItem from './RemixLinkItem.vue';

const props = defineProps<{
  allowLicenseIds: number[];
  mode: string;
  label: string;
}>();

const source = defineModel('source', { type: String as PropType<SourceType>, default: SourceType.Original });
const originals = defineModel('originals', { type: Array as PropType<Array<any>>, default: () => [] });
const forSale = computed(() => (props.mode === ModeType.Sale ? true : false));
watch(forSale, () => {
  forSale.value && (source.value = SourceType.Original);
});

const list = ref([] as any[]);
const firstLoading = ref(false);
const isLoading = ref(false);
const isFinished = ref(false);
const errorLinkTips = "Please ensure you've provided a link of template page. Double-check for any errors.";
const deleteLinkTips = 'The link to the original template that you pasted has been deleted.';
const forbidLinkTips = 'The link to the original template that you pasted cannot be remixed due to copyright restrictions.';

const deleteItem = (link: string) => {
  const idx = originals.value.findIndex((item: any) => item.link === link);
  idx > -1 && originals.value.splice(idx, 1);
};
const toLink = (link: string) => {
  window.open(link, '_blank');
};
const isRemix = computed(() => source.value === SourceType.Remix);
const searchVal = ref('');
const isSearchUrl = ref(false);
const dropMenu = ref() as any;
const pagination = reactive({
  current: 1,
  pageSize: 20,
  total: 0,
});

const clickDropdown = () => {};
const chooseItem = (option: any) => {
  if (option.supportRemix === false) {
    return;
  }
  const idx = originals.value.findIndex((item) => item.link === option.link);
  if (idx === -1) {
    originals.value.push(option);
  }
  setTimeout(() => {
    searchVal.value = '';
    list.value = [];
  }, 0);
};
let timer = null as any;
watch(
  () => searchVal.value,
  () => {
    if (timer !== null) {
      clearTimeout(timer);
    }
    firstLoading.value = true;

    timer = setTimeout(() => {
      isSearchUrl.value = isUrl(searchVal.value);
      if (isSearchUrl.value) {
        handleUrl();
      } else {
        pagination.current = 1;
        getList();
        if (dropMenu.value?.scrollTop) {
          dropMenu.value.scrollTop = 0;
        }
      }
    }, 200);
  },
);
const isUrl = (str: string) => {
  const reg = /^(https|http|ftp)\:\/\/[a-zA-Z0-9\-\.]+\.[a-zA-Z]{2,}(:[0-9]{1,5})?(\/[\S]*)?$/;
  return reg.test(str);
};
const handleUrl = () => {
  parseUrl({ url: searchVal.value }).then((res: any) => {
    firstLoading.value = false;
    const code = res.data.value.code;
    if (code === 0) {
      const arr = res.data.value.data;
      list.value = [...arr];
    } else if (code === 90701) {
      list.value = [];
      message.warning({
        content: errorLinkTips,
        key: 'errorLinkTips',
      });
    } else if (code === 90901) {
      list.value = [];

      message.warning({
        content: deleteLinkTips,
        key: 'deleteLinkTips',
      });
    } else if (code === 90702) {
      list.value = [];
      message.warning({
        content: forbidLinkTips,
        key: 'forbidLinkTips',
      });
    }
  });
};

const getList = () => {
  isLoading.value = true;
  if (pagination.current === 1) {
    list.value = [];
  }
  getLicenseList({
    q: searchVal.value,
    current: pagination.current,
    licenseIds: props.allowLicenseIds || [],
    pageSize: pagination.pageSize,
  })
    .then((res: any) => {
      const result = res.data.value.data;
      pagination.total = result.pagination.total;
      pagination.current = result.pagination.current;
      isFinished.value = pagination.current >= Math.ceil(pagination.total / pagination.pageSize);
      if (result.list && result.list.length > 0) {
        const listArr = result.list.map((item: any) => {
          return {
            ...item,
            id: item.objectId, // 去重需要字段
          };
        });
        const arr = handleDataDeduplication(list.value, listArr);
        list.value = list.value.concat(arr);
      } else {
        list.value = [];
      }
      firstLoading.value = false;
      isLoading.value = false;
    })
    .catch((err: any) => {
      console.log(err);
      firstLoading.value = false;
      isLoading.value = false;
    });
};

const handleInfiniteLoading = async () => {
  if (isLoading.value || isFinished.value) {
    return;
  }
  pagination.current++;

  getList();
};
</script>
<style lang="less">
.project-origin {
  .ant-btn-default:disabled {
    border: 1px solid rgba(0, 0, 0, 0.3);
    background-color: #f0f1f5;
  }
  .df-btn {
    border-radius: 8px;
  }
}
.remix-wrap {
  .ant-dropdown {
    z-index: 9;
    background: #fff;
    .drop-menu {
      max-height: 400px;
      background: #fff;
      overflow-y: scroll;
      border-radius: 0 0 4px 4px;
      padding: 8px 8px 8px 16px;

      box-shadow:
        0 3px 6px -4px rgba(0, 0, 0, 0.12),
        0 6px 16px 0 rgba(0, 0, 0, 0.08),
        0 9px 28px 8px rgba(0, 0, 0, 0.05);
      /* 滚动条整体 */
      &::-webkit-scrollbar {
        width: 8px;
      }
      /* 两个滚动条交接处 -- x轴和y轴 */
      &::-webkit-scrollbar-corner {
        background-color: transparent;
      }
      /* 滚动条滑块 */
      &::-webkit-scrollbar-thumb {
        border-radius: 22px;
        &:vertical {
          background-color: #eee;
        }
      }
      /* 滚动条轨道 */
      &::-webkit-scrollbar-track {
        background: transparent;
      }
    }
  }
}
</style>
