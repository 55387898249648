<template>
  <a-form-item name="machines" :validate-on-rule-change="false" class="machine-content">
    <template #label>
      <span>
        Machine and processing module
        <a-tooltip title="The combined processing mode does not currently support automatic recognition.">
          <icon-font class="ml-1 text-text-tertiary hover:text-black-hover" type="icon-question-line" />
        </a-tooltip>
      </span>
    </template>
    <ShareCommonSelectBtn
      v-if="chooseList.length"
      :class="showSelectInput ? 'mb-3' : ''"
      :list="chooseList"
      :add-btn="!!showAddBtn"
      @add="handleShowSelect"
    >
      <template #default="{ item }">
        <img v-if="item.icon" width="20" height="20" :src="getOssProcessedImage(item.icon, 'm_fill,h_20,w_20', 'webp')" class="mr-2" />
        <span>{{ item.name }}</span>
        <span class="pl-3 pr-2 text-[#09090b]">|</span>
        <span>{{ getPowerText(item.powers) }}</span>
        <icon-font type="icon-close-line" class="ml-2 cursor-pointer" @click="handleRemove(item)" />
      </template>
    </ShareCommonSelectBtn>
    <div v-show="showSelectInput" class="flex gap-2">
      <a-form-item-rest>
        <a-auto-complete
          v-model:value="selectDevice"
          :options="optionsDeviceList"
          class="flex-1"
          :allow-clear="true"
          :filter-option="handleDeviceFilter"
        >
          <template #default>
            <df-input placeholder="Machine" />
          </template>
        </a-auto-complete>
      </a-form-item-rest>
      <a-form-item-rest>
        <df-select
          v-model:value="selectPower"
          class="flex-1"
          :mode="powerMode"
          placeholder="Processing module"
          :options="optionsLaserPowerList"
          @blur="handlePowerBlur"
        />
      </a-form-item-rest>
    </div>
  </a-form-item>
</template>
<script setup lang="ts">
import { DefaultScenarios } from '@/components/share/const';
import { FileEventType } from '@/components/share/project/type';
import { type PropType } from 'vue';
import type { AssociationItem, MachineItem, Machines, Power, ScenariosItem } from '../../project/production-info/useScenariosMachine';
import { useScenariosMachine } from '../../project/production-info/useScenariosMachine';
const { scenarioMapData, getScenariosList } = useScenariosMachine();

const props = defineProps<{
  scenarios: string[]; // 以选中场景code
}>();
const emits = defineEmits(['changeScenarios']);
const powerMode = computed(() => (optionsLaserPowerList.value.length ? 'multiple' : 'tags')); // 如果是已有的设备对应的激光头，则不支持自己填入，如果没有，则支持
const { scenarios: scenariosArr } = toRefs(props);
const machines = defineModel('machines', { type: Array as PropType<Machines[]>, default: [] });
const handlePowerBlur = () => {
  if (!selectDevice.value || !selectPower.value.length) {
    return;
  }
  handleAdd();
  updateScenarios();
};
const autoFillXcsData = (data: any) => {
  data.forEach(({ extId, power }: any) => {
    const deviceCode = deviceList.value.find((item: any) => item.extId === extId)?.code;
    if (deviceCode && power) {
      const powerArr = Array.isArray(power) ? power.map(String) : [String(power)];
      const powers = addDevicePower(powerArr);
      updateMachies(deviceCode, powers);
    }
  });
  updateScenarios();
};
const triggerAutoFill = (attachFiles: any, fileEventType: FileEventType | null) => {
  if (fileEventType === FileEventType.Remove) {
    resetAllChoose();
  }
  nextTick(() => autoFillXcsData(attachFiles));
};
defineExpose({
  triggerAutoFill,
});
const resetAllChoose = () => {
  machines.value = [];
  changeScenarios([]);
};
// 自动填入机型和激光头后，更新场景
const updateScenarios = () => {
  nextTick(() => {
    const scenarios = getScenariosList(machines.value);
    scenarios.length && changeScenarios(scenarios);
  });
};
// 添加通用场景
const addGeneralScenarios = () => {
  changeScenarios([DefaultScenarios]);
};
// 机型和激光头的选中，需要自动填入对应场景
const changeScenarios = (scenarios: string[]) => {
  emits('changeScenarios', scenarios);
};
const showSelectInput = ref(true);
watch(
  machines,
  () => {
    if (!machines.value.length) {
      toggleSelectInput(true);
    } else {
      toggleSelectInput(false);
    }
  },
  { deep: true },
);
const toggleSelectInput = (status: boolean) => {
  showSelectInput.value = status;
};
const showAddBtn = computed(() => {
  return machines.value.length && !showSelectInput.value;
});
const handleShowSelect = () => {
  toggleSelectInput(true);
};
const handleDeviceFilter = (inputValue: any, options: any) => {
  if (!selectDevice.value) {
    return true;
  }
  return options.name.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
};
const addDevicePower = (power: string[]) => {
  const choosePower = laserPowerList.value.filter((item: any) => power.includes(item.code));
  return choosePower.map((item) => ({ id: item.id, name: item.name, code: item.code }));
};
const addOtherPower = (power: string[]) => {
  return power.map((item) => ({ id: 0, name: item, code: item }));
};

const handleAdd = () => {
  const deviceName = selectDevice.value ?? '';
  const device = deviceList.value.find((item: any) => item.name === deviceName);
  const powers = device ? addDevicePower(selectPower.value) : addOtherPower(selectPower.value); // 处理自定义激光头和下拉选择的激光头
  !device && addGeneralScenarios(); // 如果自定义的设备，则添加通用场景
  updateMachies(device?.code, powers, deviceName);
  selectDevice.value = '';
  selectPower.value = [];
};
const updateMachies = (device: string, powers: Array<Power>, deviceName?: string) => {
  const chooseDevice = deviceList.value.find((item: any) => item.code === device);
  const deviceId = chooseDevice?.id;
  const machine = machines.value.find((item: Machines) => item.id === deviceId || item.name === deviceName);
  if (machine) {
    // 如果有已选的机型，那就合并激光头
    machine.powers = [...machine.powers, ...powers].reduce((acc: Power[], cur: Power) => {
      if (!acc.find((item) => item.name === cur.name)) {
        acc.push(cur);
      }
      return acc;
    }, []);
  } else {
    machines.value.push({
      id: chooseDevice?.id ? chooseDevice.id : 0,
      name: chooseDevice?.name ? chooseDevice.name : selectDevice.value,
      icon: chooseDevice?.icon,
      powers,
    });
  }
};

const chooseList = computed(() => {
  return machines.value.map((item: Machines) => {
    const device = scenarioMapData.value?.machinesList?.find((machine: MachineItem) => machine.id === item.id);
    return {
      ...item,
      icon: device?.icon,
    };
  });
});

const selectDevice = ref<string>();
const selectPower = ref<string[]>([]);
watch(selectDevice, () => {
  // 选择设备后清空激光头
  selectPower.value = [];
});
const deviceList = computed(() => {
  return scenarioMapData.value?.machinesList?.map((item: any) => {
    return {
      ...item,
      value: item.name,
      label: item.name,
    };
  });
});
const laserPowerList = computed(() => {
  return scenarioMapData.value?.powersList?.map((item: any) => {
    return {
      ...item,
      value: item.code, // 激光头唯一标识
      label: item.name,
    };
  });
});

// 已选中的场景code数组
const selScenariosCode = computed(() => {
  return scenarioMapData.value.scenariosList
    .filter((item: ScenariosItem) => scenariosArr.value.includes(item.name))
    .map((item: ScenariosItem) => item.code);
});
// 已选中的场景list
const filterAssociationList = computed(() => {
  return scenarioMapData.value.associationList.filter((item: any) => selScenariosCode.value.includes(item.code));
});
// 如果没有选场景，或者选的其他场景
const unconventionalScenarios = () => {
  if (!props.scenarios.length) {
    return true;
  }
  return false;
};
// 设备下拉选项
const optionsDeviceList = computed(() => {
  if (unconventionalScenarios()) {
    // 没有选择场景，设备全量显示
    return deviceList.value;
  }
  let deviceCodeList = filterAssociationList.value.flatMap((association: any) => association.machines.map((machine: any) => machine.code));
  deviceCodeList = [...new Set(deviceCodeList || [])];
  const options = deviceList.value?.filter((item: any) => deviceCodeList.includes(item.code));
  return options;
});
// 激光头下拉选项
const optionsLaserPowerList = computed(() => {
  if (unconventionalScenarios()) {
    return laserPowerList.value;
  }
  const device = deviceList.value.find((item: any) => item.name.toUpperCase() === selectDevice.value?.toUpperCase());
  if (!selectDevice.value || !device) {
    return [];
  }
  let powerCodeList: string[] = [];
  filterAssociationList.value.forEach((item: AssociationItem) => {
    const machine = item.machines.find((machine: any) => {
      return device.code === machine.code;
    });
    if (machine) {
      powerCodeList = powerCodeList.concat(machine.laserPowers);
    }
  });
  return laserPowerList.value?.filter((item: any) => powerCodeList.includes(item.code));
});

const getPowerText = (list: Power[]) => {
  return list.map((item: Power) => item.name).join(', ');
};
const handleRemove = (item: Machines) => {
  machines.value = machines.value.filter((machine: Machines) => {
    return machine.name !== item.name; // 因为自定义内容id都为0，所以用name过滤
  });
};
</script>
<style lang="less">
.machine-content {
  .ant-btn.ant-btn-lg {
    height: 48px;
    border-radius: 8px;
  }
  .ant-select-multiple .ant-select-selection-overflow-item {
    align-self: auto;
  }
  .ant-select .ant-select-clear {
    background: transparent;
  }
}
</style>
