<template>
  <a-form ref="formRef" :rules="rules" :model="formState" layout="vertical" class="form-content">
    <div v-if="!formState.hasProduction" class="mb-4 flex items-center gap-2.5 rounded-xl bg-uni-6 px-9 py-5 text-text-primary mobile:px-6">
      <icon-font type="icon-setting" class="text-2xl" />
      <span class="text-xl font-semibold leading-7">Production Info</span>
      <df-btn type="primary" size="small" class="ml-auto" @click="changeProductionPanel(true)">
        <icon-font type="icon-Add" />
        Add
      </df-btn>
    </div>
    <CollapsePanel v-show="formState.hasProduction" v-model:active-key="activeKey" :col-key="CollapseKey.ProductionInfo" title="Production info">
      <template #extra>
        <df-btn icon="icon-Delete" size="middle" class="icon-delete" @click.stop="delProductionInfo" />
      </template>
      <ProductionTips v-if="showTips" @close="showTips = false" />
      <Software ref="softwareRef" v-model:software="formState.software" />
      <MachineProcessing
        ref="machineRef"
        v-model:machines="formState.machines"
        v-model:scenarios="formState.scenarios"
        @change-scenarios="changeScenarios"
      />
      <ApplicationScenarios v-model:scenarios="formState.scenarios" />
      <AccessoriesUsed v-model:accessories="formState.accessories" />
      <MaterialUse ref="materialRef" v-model:materials="formState.materials" />
      <MaterialInfo v-model:materialInfo="formState.materialInfo" />
      <Difficulty v-model:difficultyLevelId="formState.difficultyLevelId" />
      <TotalTime v-model:makingTime="formState.makingTime" />
      <Bom v-if="bomMaker" v-model:bom="formState.bom" />
      <div class="pb-4">
        <p class="m-0 mb-2 text-level-4 font-semibold">Settings</p>
        <AddSetting v-model:list="formState.paraSettings" />
      </div>
    </CollapsePanel>
  </a-form>
</template>
<script lang="ts" setup>
import AccessoriesUsed from '@/components/share/common/production-info/AccessoriesUsed.vue';
import ApplicationScenarios from '@/components/share/common/production-info/ApplicationScenarios.vue';
import Bom from '@/components/share/common/production-info/Bom.vue';
import Difficulty from '@/components/share/common/production-info/Difficulty.vue';
import MachineProcessing from '@/components/share/common/production-info/MachineProcessing.vue';
import MaterialInfo from '@/components/share/common/production-info/MaterialInfo.vue';
import MaterialUse from '@/components/share/common/production-info/MaterialUse.vue';
import ProductionTips from '@/components/share/common/production-info/ProductionTips.vue';
import Software from '@/components/share/common/production-info/Software.vue';
import TotalTime from '@/components/share/common/production-info/TotalTime.vue';
import AddSetting from '@/components/share/project/instruction/addSetting.vue';
import { CollapseKey, FileEventType, FormIds } from '@/components/share/project/type';
import { useShareProject } from '@/stores/shareProject';
import { useUserInfo } from '@/stores/user';
import { cloneDeep } from 'lodash-es';
import { storeToRefs } from 'pinia';
import CollapsePanel from '../../common/collapse-panel.vue';
import { useScenariosMachine } from './useScenariosMachine';
const formRef = ref();
const machineRef = ref();
const softwareRef = ref();
const materialRef = ref();
const userInfoStore = useUserInfo();
const useShareProjectInfo = useShareProject();
const { form: storeForm, requestForm, projectFileExtra, allFileExtra, activeKey } = storeToRefs(useShareProjectInfo);
const fileEventType = computed(() => useShareProjectInfo.fileEventType);
const { getScenarioAssociationApi } = useScenariosMachine();
const changeScenarios = (scenarios: string[]) => {
  if (scenarios.length) {
    formState.value.scenarios = [...new Set([...scenarios, ...formState.value.scenarios])];
  } else {
    formState.value.scenarios = [];
  }
};

watch(projectFileExtra, (newVal: any, oldVal: any) => {
  if (!oldVal || newVal.length > oldVal?.length) {
    formState.value.hasProduction = true;
  }
});
const changeProductionPanel = (status: boolean) => {
  formState.value.hasProduction = status;
  if (status) {
    useShareProjectInfo.triggerFileEvent(FileEventType.Add);
    useShareProjectInfo.changeStep(FormIds.ProductionInfo);
  } else {
    useShareProjectInfo.changeStep(FormIds.BasicInfo);
  }
};
watch(fileEventType, () => {
  if (!fileEventType.value) {
    return false;
  }
  if (formState.value.hasProduction) {
    machineRef.value.triggerAutoFill(allFileExtra.value, fileEventType.value);
    softwareRef.value?.triggerFileChange(allFileExtra.value);
    materialRef.value?.triggerAutoFill(allFileExtra.value, fileEventType.value);
  }
  useShareProjectInfo.triggerFileEvent(null);
});

const DefaultData = {
  hasProduction: false, // 是否有生产信息
  scenarios: [] as string[], // 应用场景
  software: [], // 软件
  machines: [], // 机型瓦数
  accessories: [], // 配件
  materials: [], // 耗材
  materialInfo: '', // 耗材信息
  difficultyLevelId: null, // 难度等级
  bom: {
    xtoolParts: [] as any,
    hasBom: false,
    otherParts: '',
  },
  makingTime: 0, // 制作时间
  paraSettings: [], // setting
};
const formState = ref(cloneDeep(DefaultData));
const showTips = ref(true);
watch(
  () => formState.value,
  () => {
    storeForm.value.productionForm = {
      ...formState.value,
      difficultyLevelId: formState.value.difficultyLevelId === '' ? null : formState.value.difficultyLevelId,
    };
  },
  { deep: true },
);
const handleFormat = async () => {
  try {
    await formRef.value.validateFields();
    return true;
  } catch (e) {
    console.error(e);
    return false;
  }
};
defineExpose({
  handleFormat,
});
watchEffect(() => {
  Object.keys(formState.value).forEach((key) => {
    if (requestForm.value.hasOwnProperty(key) && (requestForm.value as any)[key]) {
      (formState.value as any)[key] = (requestForm.value as any)[key];
    }
  });
});
const rules = computed(() => {
  if (!formState.value.hasProduction) {
    return {};
  }
  return {
    scenarios: [
      {
        required: true,
        message: 'Please select at least one application scenario',
        validator: async (_rule: any, value: any) => {
          if (value.length > 0) {
            return Promise.resolve();
          }
          return Promise.reject('Please select at least one application scenario');
        },
      },
    ],
    software: [{ required: true, message: 'Please select at least one software', trigger: 'change' }],
    machines: [{ required: true, message: 'Please select at least one machine model', trigger: 'change' }],
    materials: [{ required: true, message: 'Please select at least one material', trigger: 'change' }],
    xtoolParts: [
      {
        required: true,
        message: 'Please select the needed parts',
        validator: async (_rule: any, value: any) => {
          if (value.length > 0) {
            return Promise.resolve();
          }
          return Promise.reject('Please select the needed parts');
        },
      },
    ],
    difficultyLevelId: [
      {
        required: true,
        message: 'Difficulty Level is required',
        validator: async (_rule: any, value: any) => {
          if (value > 0) {
            return Promise.resolve();
          }
          return Promise.reject('Difficulty Level is required');
        },
        trigger: 'change',
      },
    ],
  };
}) as any;
// 非表单操作的情况不能触发表单验证，手动监听触发
watch(
  () => formState.value.difficultyLevelId,
  () => {
    nextTick(() => {
      formRef.value?.validateFields(['difficultyLevelId']);
    });
  },
);
watch(
  () => formState.value.machines,
  (val, val2) => {
    nextTick(() => {
      if (val.length || val2.length) {
        formRef.value.validateFields(['machines']);
      }
    });
  },
  { deep: true },
);
watch(
  () => formState.value.scenarios,
  (val) => {
    if (val.length) {
      nextTick(() => {
        formRef.value.validateFields(['scenarios']);
      });
    }
  },
);
const bomMaker = computed(() => {
  return userInfoStore.isBomMaker;
});
const resetProductionData = () => {
  Object.keys(DefaultData).forEach((key: string) => {
    Object.assign(formState.value, {
      [key]: (DefaultData as any)[key],
    });
  });
};
const delProductionInfo = () => {
  Modal.confirm({
    wrapClassName: 'confirm-modal',
    title: 'Are you sure you want to delete?',
    content: 'Production Info will be deleted, machine, materials,settings and other information will no longer be displayed.',
    okText: 'Delete',
    cancelText: 'Cancel',
    onOk: () => {
      changeProductionPanel(false);
      resetProductionData();
    },
  });
};
onMounted(() => {
  nextTick(() => {
    getScenarioAssociationApi();
  });
});
</script>
<style scoped lang="less">
.form-content {
  :deep(.ant-checkbox-wrapper) {
    color: #575757;
  }
  :deep(.ant-form-item) {
    margin-bottom: 32px;
    .ant-form-item-label > label {
      font-size: 14px;
      font-weight: theme('fontWeight.semibold');
      line-height: 22px;
      color: #1a1a1a;
    }
    .ant-input-number-affix-wrapper-focused {
      .ant-input-number-focused {
        background-color: #ffffff;
      }
      background-color: #ffffff;
    }
  }
  :deep(.ant-btn.ant-btn-lg) {
    border-radius: 8px;
  }
}
</style>
