<template>
  <div>
    <a-form-item label="Does your template require a bill of materials？" layout="vertical" class="origin-item" :name="['bom', 'hasBom']">
      <a-radio-group v-model:value="bom.hasBom">
        <a-radio :value="true">
          <span class="text-level-2">Yes</span>
        </a-radio>
        <a-radio :value="false"><span class="text-level-2">No</span></a-radio>
      </a-radio-group>
    </a-form-item>
    <a-form-item v-if="bom.hasBom === true" class="bom-select mt-0" :name="['bom', 'xtoolParts']">
      <template #label>
        <div>
          Choose parts or kits from
          <a href="https://www.xtool.com/pages/xtool-selected-materials" target="_blank" class="text-status-alert hover:text-status-alert">
            xTool.com
          </a>
        </div>
      </template>
    </a-form-item>
    <div v-if="bom.hasBom === true" class="relative">
      <div class="absolute right-0 top-[-28px] text-[#9e9e9e]">{{ bom?.xtoolParts?.length || 0 }}/10</div>
      <EditBomSelect :list="bom.xtoolParts" @delete-item="deleteItem" />
      <div v-if="showSelect" class="flex gap-2 not-pc:flex-col">
        <div class="flex w-[calc(100%-300px)] flex-1 gap-4 not-pc:w-full">
          <a-cascader
            v-model:value="currentSelect.value"
            :show-search="{ filter }"
            :options="bomList"
            size="large"
            :field-names="{ label: 'title', value: 'shId', children: 'variants' }"
            placeholder="Please select the needed parts "
            class="w-full not-pc:w-full"
          />
        </div>
        <div class="flex justify-between gap-2">
          <a-input-number v-model:value="currentSelect.quantity" size="large" placeholder="Quantity" style="width: 90px" :min="1" />
          <df-btn
            :class="['flex items-center']"
            :disabled="!(currentSelect.quantity && currentSelect.value && currentSelect.quantity > 0)"
            type="primary"
            shape="default"
            @click="addSelect"
          >
            <icon-font type="icon-check-line" class="text-lg leading-3" />
            Add
          </df-btn>
          <div
            :class="[
              'flex h-[40px] w-[40px] cursor-pointer items-center justify-center rounded-md border border-solid border-[#EDEDED] hover:text-error',
            ]"
            @click="deleteSelect"
          >
            <icon-font type="icon-Delete" class="text-lg" />
          </div>
        </div>
      </div>
      <a-button
        v-if="!showSelect && bom.xtoolParts.length < 10"
        class="flex w-full items-center justify-center"
        type="dashed"
        @click="showSelect = true"
      >
        <template #icon>
          <icon-font type="icon-add-line" class="text-lg leading-4" />
        </template>
        Add more parts
      </a-button>
    </div>
    <a-form-item v-if="bom.hasBom === true" name="otherParts" label="List other parts" layout="vertical" class="mt-4">
      <df-textarea
        v-model:value="bom.otherParts"
        placeholder="Please list any additional parts necessary for your design that are not included in the parts above."
        :auto-size="{ minRows: 5 }"
        :maxlength="500"
        show-count
      />
    </a-form-item>
  </div>
</template>

<script setup lang="ts">
import { getBomList } from '@/api/project';
import type { ShowSearchType } from 'ant-design-vue/es/cascader';
const bom = defineModel('bom', { type: Object, default: () => ({ hasBom: false, xtoolParts: [], otherParts: '' }) });
const filter: ShowSearchType['filter'] = (inputValue: any, path: any) => {
  return path.some((option: any) => option.title.toLowerCase().indexOf(inputValue.toLowerCase()) > -1);
};
const bomList = ref([] as any[]);
const showSelect = ref(true);
const currentSelect = reactive({
  value: undefined as undefined | string[],
  quantity: undefined as undefined | number,
});

watch(
  () => currentSelect.value,
  () => {
    if (currentSelect.value) {
      currentSelect.quantity = 1;
    }
  },
);
const deleteSelect = () => {
  reset();
};
const addSelect = () => {
  const selectIds = currentSelect.value || [];
  if (selectIds.length > 0) {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    let selectedFirst = bomList.value.filter((item: any) => item.shId === selectIds![0]) as any;
    let selectedSecond: any = null;
    if (selectedFirst[0]?.variants && selectIds.length > 1) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      selectedSecond = selectedFirst[0].variants.filter((item: any) => item.shId === selectIds![1]);
    }
    let selected = selectedSecond ? selectedSecond[0] : selectedFirst[0];
    const image = selected.image ? selected.image : selectedFirst[0].image;
    // 已经添加过的商品，只增加数量
    if (!bom.value.xtoolParts) {
      bom.value.xtoolParts = [];
    }
    const idx = bom.value.xtoolParts.findIndex((item: any) => item.productId === selected.shId || item.variantId === selected.shId);
    if (idx === -1) {
      bom.value.xtoolParts.push({
        ...selected,
        variantTitle: selectedSecond ? selectedSecond[0].title : '',
        productTitle: selectedFirst[0].title,
        variantId: selectedSecond ? selectedSecond[0].shId : selectedFirst[0].shId,
        productId: selectedFirst[0].shId,
        quantity: currentSelect.quantity,
        image,
      });
    } else {
      bom.value.xtoolParts[idx].quantity += currentSelect.quantity;
    }
    reset();
  }
};
const reset = () => {
  currentSelect.value = undefined;
  currentSelect.quantity = undefined;
  showSelect.value = false;
};
const deleteItem = (idx: number) => {
  bom.value.xtoolParts.splice(idx, 1);
};
const getData = async () => {
  const { data } = await getBomList();
  bomList.value = data.value.data.list;
};
watch(
  () => bom.value.hasBom,
  () => {
    if (bom.value.hasBom === false) {
      bom.value.xtoolParts = [];
      currentSelect.value = undefined;
      currentSelect.quantity = undefined;
    }
  },
);
onMounted(() => {
  nextTick(getData);
});
</script>
<style scoped lang="less">
:deep(.ant-input-textarea-show-count::after) {
  position: absolute;
  right: 7px;
  bottom: 5px;
}
.bom-select {
  :deep(.ant-form-item-control-input) {
    min-height: 0;
  }
  :deep(.ant-form-item-explain) {
    position: relative;
    height: 0;
    top: 32px;
  }
}
</style>
